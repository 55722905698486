/*!
 * Landing Page Style Sheet
 */

$break-s: 450px;
$break-mobile: 660px;
$break-m: 900px;
$break-l: 1200px;
$break-xl: 1600px;

.text-muted {
  color: #777;
}

.text-primary {
  color: #fed136;
}

.cta-banner .contactButton {
  float: left;
  padding: 6px 30px;
  background: #252f35;
  border: solid 2px #252f35;
  color: #ffffff;
  font-size: 22px;
  margin-right: 20px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
}

.cta-banner .contactButton:hover {
  background: transparent;
  border: solid 2px #f0dca4;
  color: #c9b585;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
}

#phoneCircleIcon {
  float: left;
  cursor: pointer;
  width: 60px;
  height: 60px;
  display: block;
  margin: 20px 20px;
  border-radius: 100%;
  background: #e7d198;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  img {
    margin: 12px;
  }
}

.landing-cta {
  position: fixed;
  border-top: solid 2px #5a6a73;
  bottom: 0;
  background: #252f35;
  width: 100%;
  z-index: 9999;
  padding: 0 5px;
  background: rgba(37, 47, 53, 0.9);

  p {
    line-height: 28px;
    letter-spacing: 2px;
    color: #fff;
    float: left;
    margin: 22px 0 0 20px;
    font-size: 20px;
    width: calc(100% - 120px);

    b {
      font-size: 34px;
    }
  }
}

.img-centered {
  margin: 0 auto;
}

.bg-light-gray {
  background-color: #f7f7f7;
}

.bg-darkest-gray {
  background-color: #222;
}

.btn-primary {
  border-color: #fed136;
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #fff;
  background-color: #fed136;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  border-color: #f6bf01;
  color: #fff;
  background-color: #fec503;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  border-color: #fed136;
  background-color: #fed136;
}

.btn-primary .badge {
  color: #fed136;
  background-color: #fff;
}

.btn-xl {
  padding: 20px 40px;
  border-color: #fed136;
  border-radius: 3px;
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: #fed136;
}

.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  border-color: #f6bf01;
  color: #fff;
  background-color: #fec503;
}

.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
  background-image: none;
}

.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
  border-color: #fed136;
  background-color: #fed136;
}

.btn-xl .badge {
  color: #fed136;
  background-color: #fff;
}

.navbar-default {
  border-color: transparent;
  background-color: #222;
}

nav.navbar.navbar-default {
  border-radius: 5px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.22);
}

.navbar-default .navbar-brand {
  font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
  color: #fed136;
  padding: 5px;
  @media screen and (max-width: $break-mobile) {
    float: none;
  }
}

.navbar-default .navbar-brand img {
  height: 44px;

  @media screen and (max-width: $break-mobile) {
    margin-left: 40px;
  }
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:active,
.navbar-default .navbar-brand.active {
  color: #fec503;
}

.navbar-default .navbar-collapse {
  border-color: rgba(255, 255, 255, 0.02);
}

.navbar-default .navbar-toggle {
  border-color: #fed136;
  background-color: #fed136;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fed136;
}

.navbar-default .nav li a {
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  color: #fff;
}

.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus {
  outline: 0;
  color: #fed136;
}

.navbar-default .navbar-nav {
  margin-right: calc(2% + 15px);
}

.navbar-default .navbar-nav > .active > a {
  border-radius: 0;
  color: #fff;
  background-color: #fed136;
}

.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #fec503;
}

.phone-cta {
  color: #fff;
  text-align: center;
  margin-top: -10px;
}

.right {
  float: right;
}

.phone-cta span#phoneNumber {
  font-size: 38px;
  /* margin: 5px 0; */
  color: #e7d198;
  line-height: 40px;
  letter-spacing: 0;
}

.phone-cta span {
  font-size: 18px;
  letter-spacing: 0;
}

@media (min-width: 768px) {
  .navbar-default {
    padding: 25px 0 10px 0;
    border: 0;
    background-color: transparent;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
  }

  .navbar-default .navbar-brand {
    font-size: 2em;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .navbar-default .navbar-nav > .active > a {
    border-radius: 3px;
  }

  .navbar-default.navbar-shrink {
    padding: 10px 0;
    background-color: #222;
  }

  .navbar-default.navbar-shrink .navbar-brand {
    font-size: 1.5em;
  }
}

header {
  text-align: center;
  color: #fff;
  background-attachment: scroll;
  background-image: url(../imgs/header-bg.jpg);
  background-position: center center;
  background-repeat: none;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

header .intro-text {
  padding-top: 100px;
  text-align: left;
}

header .intro-text .intro-lead-in {
  margin-bottom: 25px;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: italic;
  line-height: 22px;
}

header .intro-text .intro-heading {
  margin-bottom: 25px;
  text-transform: uppercase;
  font-family: "EB Garamond", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
}

.cta-banner {
  padding: 30px 0 30px 0;
  background: #e7d198;
  border-top: solid 4px #d2be8b;
  border-bottom: solid 4px #d2be8b;
}

.cta-banner h1 {
  color: rgba(0, 0, 0, 0.7);
}

.cta-banner p {
  font-size: 28px;
  color: rgba(0, 0, 0, 0.48);
  margin: 0;
}

.cta-banner p a {
  font-size: 32px;
  font-weight: 600px !important;
}

.cta-banner p a:hover {
  font-size: 32px;
  font-weight: 600px !important;
}

.cta-form-wrapper {
  padding: 30px 36px;
  border: solid 2px #e7d198;
  background: #252f35;
  border-radius: 10px;
  margin-bottom: -100px;
  @media screen and (max-width: 980px) {
    margin-bottom: 80px;
  }
}

i.fa.fa-phone {
  font-size: 20px;
  margin-right: 10px;
  color: #e7d198;
}

.section-header {
  margin-top: 100px;
  width: 100%;
  padding-bottom: 40px;
  float: left;
  text-align: left;
  border-bottom: 2px solid #d5d5d5;
}

.section-header em {
  font-family: "EB Garamond", serif;
  font-size: 32px;
  color: #e7d198;
}

.section-header h3 {
  font-family: "EB Garamond", serif;
  font-size: 42px;
  color: #777;
  margin-top: 12px;
}

.money-recovered {
  text-align: left;
  font-size: 72px;
  font-family: "EB Garamond", serif;
  color: #e7d198;
  line-height: 60px;
}

.money-recovered em {
  font-size: 60px;
  color: #777777;
}

.money-recovered span {
  color: #777777;
  font-size: 44px;
  line-height: 30px;
}

#homeAbout p {
  font-size: 22px;
  line-height: 34px;
  margin-top: 28px;
  font-style: italic;
  color: #777;
}

.form-group {
  margin-bottom: 20px;
}

.section-content {
  padding-top: 60px;
  font-size: 22px;
  margin-top: 10px;
  line-height: 34px;
  color: #777;
  font-style: italic;
  margin-bottom: 100px;
}

.cta-form-wrapper .form-title {
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 26px;
  line-height: 32px;
}

.cta-form-wrapper .form-title span#phoneNumber {
  font-size: 32px;
  /* margin: 5px 0; */
  color: #e7d198;
  line-height: 50px;
}

.cta-form-wrapper .form-title span {
  font-size: 26px;
}

.cta-form-wrapper .btn-l {
  padding: 12px;
  width: 100%;
  font-size: 18px;
  background: #3584c7;
  margin-bottom: 10px;
}

.check-mark-bullet {
  text-align: left;
  list-style-image: url(../img/check-mark.png);
  margin-bottom: 20px;
}

.check-mark-bullet p {
  margin: 0 0;
  font-size: 24px;
  line-height: 30px;

  @media screen and (max-width: $break-m) {
    font-size: 18px;
    line-height: 20px;
  }
}

.form-control {
  height: 40px;
}

.intro-tagline {
  text-align: left;
  font-size: 24px;
  line-height: 28px;
  margin: 14px 0 28px 0;

  @media screen and (max-width: $break-m) {
    font-size: 18px;
    line-height: 22px;
    margin: 14px 0 8px 0;
  }
}

@media (min-width: 768px) {
  header .cta-form-wrapper {
    margin-top: 160px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  header .intro-text {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
    padding-top: 140px;
  }

  header .intro-text .intro-lead-in {
    margin-bottom: 25px;
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 40px;
    font-style: italic;
    line-height: 40px;
  }

  header .intro-text .intro-heading {
    margin-bottom: 30px;
    text-transform: uppercase;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
  }
}

section h2.section-heading {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 40px;
}

section h3.section-subheading {
  margin-bottom: 75px;
  text-transform: none;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.landing-home-intro {
  background: #efefef;
}

.service-heading {
  margin: 15px 0;
  text-transform: none;
}

#portfolio .portfolio-item {
  right: 0;
  margin: 0 0 15px;
}

#portfolio .portfolio-item .portfolio-link {
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 400px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(254, 209, 54, 0.9);
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
  opacity: 1;
}

#portfolio
  .portfolio-item
  .portfolio-link
  .portfolio-hover
  .portfolio-hover-content {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 20px;
  margin-top: -12px;
  text-align: center;
  font-size: 20px;
  color: #fff;
}

#portfolio
  .portfolio-item
  .portfolio-link
  .portfolio-hover
  .portfolio-hover-content
  i {
  margin-top: -12px;
}

#portfolio
  .portfolio-item
  .portfolio-link
  .portfolio-hover
  .portfolio-hover-content
  h3,
#portfolio
  .portfolio-item
  .portfolio-link
  .portfolio-hover
  .portfolio-hover-content
  h4 {
  margin: 0;
}

#portfolio .portfolio-item .portfolio-caption {
  margin: 0 auto;
  padding: 25px;
  max-width: 400px;
  text-align: center;
  background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption h4 {
  margin: 0;
  text-transform: none;
}

#portfolio .portfolio-item .portfolio-caption p {
  margin: 0;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: italic;
}

#portfolio * {
  z-index: 2;
}

@media (min-width: 767px) {
  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  background-color: #f1f1f1;
}

.timeline > li {
  position: relative;
  margin-bottom: 50px;
  min-height: 50px;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  float: right;
  position: relative;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li .timeline-image {
  z-index: 100;
  position: absolute;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  border: 7px solid #f1f1f1;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  background-color: #fed136;
}

.timeline > li .timeline-image h4 {
  margin-top: 12px;
  font-size: 10px;
  line-height: 14px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body > p,
.timeline .timeline-body > ul {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }

  .timeline > li {
    margin-bottom: 100px;
    min-height: 100px;
  }

  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }

  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }

  .timeline > li .timeline-image h4 {
    margin-top: 16px;
    font-size: 13px;
    line-height: 18px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline > li .timeline-image h4 {
    margin-top: 30px;
    font-size: 18px;
    line-height: 26px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }

  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }

  .timeline > li .timeline-image h4 {
    margin-top: 40px;
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.team-member {
  margin-bottom: 50px;
  text-align: center;
}

.team-member img {
  margin: 0 auto;
  border: 7px solid #fff;
}

.team-member h4 {
  margin-top: 25px;
  margin-bottom: 0;
  text-transform: none;
}

.team-member p {
  margin-top: 0;
}

aside.clients img {
  margin: 50px auto;
}

section#contact {
  background-color: #222;
  background-image: url(../img/map-image.png);
  background-position: center;
  background-repeat: no-repeat;
}

section#contact .section-heading {
  color: #fff;
}

section#contact .form-group {
  margin-bottom: 25px;
}

section#contact .form-group input,
section#contact .form-group textarea {
  padding: 15px;
}

section#contact .form-group input.form-control {
  height: auto;
}

section#contact .form-group textarea.form-control {
  height: 236px;
}

section#contact .form-control:focus {
  border-color: #fed136;
  box-shadow: none;
}

section#contact::-webkit-input-placeholder {
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #bbb;
}

section#contact:-moz-placeholder {
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #bbb;
}

section#contact::-moz-placeholder {
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #bbb;
}

section#contact:-ms-input-placeholder {
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #bbb;
}

section#contact .text-danger {
  color: #e74c3c;
}

footer {
  padding: 25px 0;
}

footer span.copyright {
  text-transform: uppercase;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 40px;
}

footer ul.quicklinks {
  margin-bottom: 0;
  text-transform: uppercase;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 40px;
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 20px;
  line-height: 40px;
  outline: 0;
  color: #fff;
  background-color: #222;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
  background-color: #fed136;
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
  outline: 0;
}

.portfolio-modal .modal-content {
  padding: 100px 0;
  min-height: 100%;
  border: 0;
  border-radius: 0;
  text-align: center;
  background-clip: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.portfolio-modal .modal-content h2 {
  margin-bottom: 15px;
  font-size: 3em;
}

.portfolio-modal .modal-content p {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content p.item-intro {
  margin: 20px 0 30px;
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: italic;
}

.portfolio-modal .modal-content ul.list-inline {
  margin-top: 0;
  margin-bottom: 30px;
}

.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}

.portfolio-modal .close-modal {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 75px;
  height: 75px;
  background-color: transparent;
  cursor: pointer;
}

.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}

.portfolio-modal .close-modal .lr {
  z-index: 1051;
  width: 1px;
  height: 75px;
  margin-left: 35px;
  background-color: #222;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.portfolio-modal .close-modal .lr .rl {
  z-index: 1052;
  width: 1px;
  height: 75px;
  background-color: #222;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.portfolio-modal .modal-backdrop {
  display: none;
  opacity: 0;
}

::-moz-selection {
  text-shadow: none;
  background: #fed136;
}

::selection {
  text-shadow: none;
  background: #fed136;
}

img::selection {
  background: 0 0;
}

img::-moz-selection {
  background: 0 0;
}
