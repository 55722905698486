@import url('http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800');
@import url('http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500italic,500,700,700italic,900italic,900');

.mega-col-1 {
  width: 8.33%;
  float: left;
}
.mega-col-2 {
  width: 16.66%;
  float: left;
}
.mega-col-3 {
  width: 25%;
  float: left;
}
.mega-col-4 {
  width: 33.33%;
  float: left;
}
.mega-col-5 {
  width: 41.6%;
  float: left;
}
.mega-col-6 {
  width: 50%;
  float: left;
}
.mega-col-7 {
  width: 58.33%;
  float: left;
}
.mega-col-8 {
  width: 66.66%;
  float: left;
}
.mega-col-9 {
  width: 75%;
  float: left;
}
.mega-col-10 {
  width: 83.33%;
  float: left;
}
.mega-col-11 {
  width: 91.66%;
  float: left;
}
.mega-col-12 {
  width: 100%;
  float: left;
}

/************************/

.tgl-megadrop .has-submenu .submenu-wrapper {
  display: none;
  width: 100%;
  background: #efefef;
  position: absolute;
  border: solid 3px #252f35;
  z-index: 99;
  padding: 20px;
  margin-top: 88px;
}

.tgl-megadrop .has-submenu:hover > .tgl-megadrop .has-submenu .submenu-wrapper {
  display: block;
}

.submenu-wrapper.mega-full-width div img {
  margin: 25px 25px;
}

.mega-col-img {
  padding: 25px;
}
.mega-col-img img {
  width: 90%;
}
.mega-title {
  border-bottom: solid 2px rgb(199, 199, 199);
  font-weight: 600;
  margin-left: 20px;
  padding-bottom: 10px;
  margin-right: 45px;
  margin-bottom: 4%;
}
.mega-title h2 {
  color: #777777;
  font-size: 26px;
  font-weight: 200;
}
.submenu-menu {
  margin: 0;
  padding: 0;
}
.submenu-menu:first-child {
  margin-left: 15px;
}

.submenu-menu li {
  margin: 7px 0px 7px 0px;
  width: 100%;
}
.submenu-menu li:first-child {
  margin: 0px 0px 7px 0px;
}
.submenu-menu li a {
  color: #777777 !important;
  text-align: left;
  font-size: 20px;
  margin: 0;
  padding: 0;
  line-height: 110%;
}

.mega-footer {
  padding: 25px 0 0 0;
}

.mega-footer a {
  position: absolute;
  color: #888888 !important;
  margin: 15px 0;
  font-weight: bold !important;
  bottom: 0;
}
