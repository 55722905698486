// @import url(https://fonts.googleapis.com/css?family=EB + Garamond);
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond');
@import url('https://fonts.googleapis.com/css?family=Lily+Script+One');
@import "mega.scss";
@import "landing-page.scss";
@import "../../bower_components/bootstrap/scss/bootstrap.scss";

$break-s: 450px;
$break-mobile: 660px;
$break-m: 900px;
$break-l: 1200px;
$break-xl: 1600px;

#bp-web-wrapper {
  overflow: hidden;
}

button:focus {
  outline: 0;
}

.container {
  @media screen and (max-width: $break-s) {
    padding: 0 0;
  }
}

body {
  font-family: "EB Garamond", serif;
  color: rgb(119, 119, 119);
}

#dbaClaims {
  background-color: #ebebeb;
}

#navBar {
  width: 100%;
  z-index: 9999999;
  position: fixed;
  top: 0;
  background: #252f35;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.navbar-brand {
}

#logo {
  float: left;
  padding: 15px 25px;
  line-height: 84px;
  color: #fff;
  width: 400px;
  font-size: 36px;
}

#mainMenu {
  float: right;
  padding: 0 25px 0 25px;
  margin: 0;
  font-size: 22px;
  line-height: 90px;
}

#logo p {
  margin: 0;
}

#mainMenu li {
  display: inline;
  float: left;
}

#mainMenu li a {
  display: inline;
  text-decoration: none;
  padding: 0 25px;
  color: #fff;
  float: left;
  font-weight: 300;
}

#mainMenu li a:hover {
  color: #e7d198;
}

#mainMenu li a.active {
  color: #e7d198;
}

.page-content {
  padding-top: 85px;
}

.section-header {
  margin-top: 100px;
  width: 100%;
  padding-bottom: 40px;
  float: left;
  text-align: left;
  border-bottom: 2px solid rgb(213, 213, 213);
  @media screen and (max-width: $break-s) {
    margin: 100px 20px 30px 20px;
    width: 90%;
  }
}

#homeMap .section-header {
  @media screen and (max-width: $break-m) {
    margin-bottom: 20px;
  }
}

#contact .section-header {
  margin-bottom: 80px;
  @media screen and (max-width: $break-m) {
    margin-bottom: 20px;
  }
}

#headerdivider {
  width: 100%;
  border-bottom: 2px solid rgb(213, 213, 213);
  position: absolute;
  bottom: 0;
}

#dividerStart {
  margin-top: 37px;
  margin-bottom: -40px;
  width: 20%;
  bottom: 0;
  border-bottom: 5px solid rgb(231, 209, 152);
}

.section-header h3 {
  font-family: "EB Garamond", serif;
  font-size: 42px;
  color: #777;
  margin-top: 12px;
  @media screen and (max-width: $break-s) {
    font-size: 38px;
  }
}

.section-header em {
  font-family: "EB Garamond", serif;
  font-size: 32px;
  color: #e7d198;
}

.section-subheader {
  margin-top: 40px;
  width: 100%;
  padding-bottom: 20px;
  float: left;
  text-align: left;
  margin-bottom: 40px;
}

.section-subheader h3 {
  font-family: "EB Garamond", serif;
  font-size: 36px;
  color: #777;
  margin-top: 12px;
}

.section-subheader em {
  font-family: "EB Garamond", serif;
  font-size: 32px;
  color: #e7d198;
}

#subHeaderDivider {
  width: 20%;
  margin-top: 17px;
  border-bottom: 4px solid rgb(231, 209, 152);
  position: absolute;
}

.section-content {
  padding-top: 60px;
  font-size: 22px;
  margin-top: 10px;
  line-height: 34px;
  color: #777;
  font-style: italic;
  margin-bottom: 100px;
}

.section-content p {
  margin: 0 0 20px;
}

.block-quote {
  height: 110px;
  font-size: 1.8em;
  margin: 36px !important;
  padding-left: 8%;
  padding-top: 0.2%;
  line-height: 120%;
  border-left: solid 4px #1d5f86;
}

.block-quote #openQuote {
  color: #adadad;
  font-size: 2.2em;
  margin-left: -5%;
  margin-top: 1%;
  position: absolute;
  font-family: "Lily Script One", cursive;
  z-index: 99;
}

.block-quote #closeQuote {
  color: #adadad;
  font-size: 2.2em;
  margin-top: 3.5%;
  position: absolute;
  font-family: "Lily Script One", cursive;
  z-index: 99;
}

.numbered-li {
  list-style-type: decimal;
}

.bold-start span {
  font-weight: 600;
  color: #1d5f86;
}

#cta {
  background: #e7d198;
}

#cta .contactButton {
  padding: 6px 30px;
  background: #415b6e;
  border: 0;
  color: #ffffff;
  font-size: 22px;
  margin: 32px;
  float: right;
  border-radius: 1px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  @media screen and (max-width: $break-m) {
    margin: 0 0 30px 0;
    float: none;
  }
}

#cta .contactButton:hover {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

#cta p {
  float: left;
  font-size: 34px;
  color: #fff;
  padding: 28px;
  margin: 0;
}

.circle-cta {
  z-index: 999;
  margin: 100px 25px 0 0;
  float: right;
}

.circle-cta a img {
  margin: 12px;
}

.circle-cta-wrapper {
  right: 0;
  position: fixed;
  z-index: 999;
}

.circle-cta a {
  cursor: pointer;
  width: 60px;
  height: 60px;
  display: block;
  margin: 20px 0;
  border-radius: 100%;
  background: #e7d198;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.circle-cta a:hover {
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#phoneCta {
  background: #e7d198 url(assets/imgs/phone-icon-small.png);
  background-size: 100%;
}

#emailCta {
  background: #e7d198 url(assets/imgs/mail-icon-small.png);
  background-size: 100%;
}

.mega-menu-wrapper {
  position: absolute;
  top: 90px;
  right: 80px;
  width: 640px;
  height: 215px;
}

#practiceArea.mega-menu-wrapper {
  position: absolute;
  top: 90px;
  right: 80px;
  width: 840px;
  height: 300px;
}

#defenseBaseAct.mega-menu-wrapper {
  position: absolute;
  top: 90px;
  right: 80px;
  width: 840px;
  height: 320px;
}
.sub-menu-title {
  padding-bottom: 5px;
}

.submenu-item {
  margin-top: 15px;
}

.mega-menu {
  overflow: hidden;
  margin: 0 auto;
  background: #efefef;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  padding: 10px;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  width: 100%;
  height: 100%;
  border: #252f35 solid 2px;
  opacity: 1;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.mega-menu-content {
  position: absolute;
  width: 640px;
}

#practiceArea .mega-menu-content {
  position: absolute;
  width: 840px;
}

#defenseBaseAct .mega-menu-content {
  position: absolute;
  width: 840px;
}

.mega-drop-img {
  margin-left: -10px;
  padding: 10px 0;
}

.mega-drop-img img {
  width: 100%;
}

.submenu-item-wrapper li {
  width: 100%;
  font-size: 18px;
  margin: 5px 0;
}

#mainMenu .submenu-item li a {
  line-height: 125%;
  padding: 0 2px;
  position: relative;
  color: rgb(119, 119, 119);
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

#mainMenu .submenu-item li a:hover {
  cursor: pointer;
  color: #e7d198;
  padding-left: 5px;
}

.float-right {
  margin: 0 0 !important;
  float: right;
}

.submenu-item-wrapper h2 {
  line-height: 100%;
  margin-top: 25px;
  font-size: 24px;
}

.submenu-item-wrapper #headerdivider {
  width: 90%;
}

.submenu-item-wrapper #dividerStart {
  margin-top: -1px;
  width: 40%;
  border-bottom: 3px solid rgb(231, 209, 152);
}

.submenu-item-wrapper ul {
  padding: 0;
}

/*////////////////////////////////////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////// Home Page /////////////////////////////////////////*/
/*////////////////////////////////////////////////////////////////////////////////////////////*/

#homePage #navBar {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);

  @media screen and (max-width: $break-mobile) {
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  }
}

#logo h3 {
  float: left;
  font-size: 40px;
  margin: 0 0 0 20px;
  line-height: 40px;
  color: #e7d198;
}

#logo img {
  float: left;
  @media screen and (max-width: $break-l) {
    width: 75%;
  }
}

#triangleOverlay {
  width: 1100px;
  height: 0;
  z-index: -1;
  top: -5px;
  left: 0;
  position: absolute;
  opacity: 0.7;
  border-bottom: 1080px solid #263238;
  border-right: 700px solid transparent;
  @media screen and (max-width: $break-mobile) {
    border-right: 500px solid transparent;
  }
}

#slide1 {
  background: url(/imgs/BG-Sample-3.jpg) no-repeat center;
  background-size: cover;
  background-position: 0 0;
}

#slide2 {
  background: url(/imgs/BG-Sample-1.jpg) no-repeat center;
  background-size: cover;
}

#slide3 {
  background: url(/imgs/BG-Sample-2.jpg) no-repeat center;
  background-size: cover;
}

#slide4 {
  background: url(/imgs/BG-Sample-4.jpg) no-repeat center;
  background-size: cover;
}

.slideContent {
  font-family: "EB Garamond", serif;
  position: relative;
  z-index: 1;
  color: #fff;
  width: 100%;
  padding-left: 5%;
  padding-top: 16%;
  height: 950px;
  @media screen and (max-width: $break-xl) {
    height: 860px;
  }
  @media screen and (max-width: $break-l) {
    height: 760px;
    padding-top: 10%;
  }
  @media screen and (max-width: $break-m) {
    height: 640px;
    padding-top: 18%;
  }
}

.slideContent h1 {
  font-size: 72px;
  margin-bottom: 2%;
  @media screen and (max-width: $break-xl) {
    font-size: 62px;
  }

  @media screen and (max-width: $break-l) {
    font-size: 46px;
  }
}

#homePageBanner .slick-slider {
  margin-bottom: 0;
}

.slideContent h1 em {
  font-size: 42px;
  color: #e7d198;
  @media screen and (max-width: $break-l) {
    font-size: 32px;
  }
}

.slideContent h2 {
  font-size: 38px;
  font-style: italic;
}

.slideContent p {
  font-size: 22px;
  line-height: 32px;
  padding: 5px 0 20px 0;
  width: 33%;
  @media screen and (max-width: $break-xl) {
    width: 38%;
    font-size: 18px;
  }
  @media screen and (max-width: $break-l) {
    width: 40%;
  }
  @media screen and (max-width: $break-m) {
    width: 60%;
  }
  @media screen and (max-width: $break-s) {
    width: 95%;
  }
}

.homeButton {
  padding: 10px 40px;
  background: rgba(0, 0, 0, 0);
  border: solid 1px #e7d198;
  color: #ffedbd;
  font-size: 22px;
  margin-right: 20px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  @media screen and (max-width: $break-s) {
    padding: 8px 18px;
  }
}

.homeButton:hover {
  background: #e7d198;
  color: #fff;
}

#homeIntro {
  background: #efefef;
  padding-bottom: 100px;
  border-bottom: solid 2px #ebebeb;
  @media screen and (max-width: $break-m) {
    padding-bottom: 0;
  }
}

.money-recovered {
  text-align: left;
  font-size: 72px;
  font-family: "EB Garamond", serif;
  color: rgb(231, 209, 152);
  line-height: 60px;
  @media screen and (max-width: $break-s) {
    font-size: 48px;
    line-height: 45px;
    border-bottom: solid 2px #d8d8d8;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

.money-recovered span {
  color: rgb(119, 119, 119);
  font-size: 44px;
  line-height: 30px;

  @media screen and (max-width: $break-s) {
    font-size: 28px;
  }
}

.money-recovered em {
  font-size: 60px;
  color: rgb(119, 119, 119);
  @media screen and (max-width: $break-s) {
    font-size: 42px;
  }
}

.cases-won {
  text-align: left;
  font-size: 64px;
  font-family: "EB Garamond", serif;
  color: rgb(231, 209, 152);
  line-height: 50px;

  @media screen and (max-width: $break-m) {
    padding: 40px 0 30px 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  }
  @media screen and (max-width: $break-s) {
    font-size: 48px;
    line-height: 45px;
  }
}

.cases-won em {
  font-size: 60px;
  line-height: 80px;
  color: rgb(119, 119, 119);
  @media screen and (max-width: $break-s) {
    font-size: 44px;
    line-height: 68px;
  }
}

.cases-won span {
  font-size: 36px;
  color: rgb(119, 119, 119);
  @media screen and (max-width: $break-s) {
    padding-left: 10px;
    font-size: 28px;
  }
}

#homeAbout {
  text-align: left;
  font-family: "EB Garamond", serif;
}

#homeAbout p {
  font-size: 22px;
  line-height: 34px;
  margin-top: 28px;
  font-style: italic;
  color: #777;
}

#homeAbout h1 {
  color: #777;
}

#homeTeam {
  padding-bottom: 100px;
  border-bottom: solid 2px #ebebeb;
  @media screen and (max-width: $break-mobile) {
    padding-bottom: 0;
  }
}

.team-member-wrapper-solo {
  img {
    max-width: 100%;
  }

  @media screen and (max-width: $break-m) {
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  }
}

#teamMembers {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  div {
    @media screen and (max-width: $break-m) {
      &:last-of-type .team-member-wrapper-solo {
        padding-bottom: 0;
        border-bottom: 0 solid rgba(0, 0, 0, 0);
      }
    }
  }

  p {
    line-height: 26px;
    font-size: 18px;
    font-style: italic;
  }
  
  h4 {
    font-style: italic;
    color: #dbc17d;
  }
  
  img {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}


#homeServices {
  background: #efefef;
  padding-bottom: 70px;
  border-bottom: solid 2px #ebebeb;
 
  @media screen and (max-width: $break-m) {
    padding-bottom: 0;
  }

  #icon {
    float: left;
    background: rgba(51, 51, 51, 0);
    height: 60px;
    outline: #e7d198 solid 1px;
    width: 60px;
    border: double 4px #e7d198;
    margin-right: 25px;
    margin-bottom: 5px;

    img {
      width: 100%;
    }
  }

  .services {
    font-size: 18px;
    margin-top: 10px;
    line-height: 26px;
    color: #777;
    font-style: italic;
  }
}

.services-wrapper h2 {
  margin-top: -10px;
  margin-bottom: 5px;
  line-height: 30px;
}

.service-container {
  margin-top: 20px;
  margin-bottom: 30px;
  @media screen and (max-width: $break-m) {
    margin-top: 40px;
    padding-bottom: 21px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  }
}

.service-btn {
  color: #2b5387;
  font-size: 22px;
  font-style: italic;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
}

#homeMap {
  background-size: cover;
  padding-bottom: 100px;
  border-bottom: solid 2px #ebebeb;
  @media screen and (max-width: $break-m) {
    padding-bottom: 0;
  }
}

.total-cases {
  text-align: left;
  font-size: 72px;
  font-family: "EB Garamond", serif;
  color: rgb(231, 209, 152);
  line-height: 60px;
  @media screen and (max-width: $break-s) {
    font-size: 58px;
  }
}

.total-cases em {
  font-size: 60px;
  color: rgb(119, 119, 119);
}

.total-cases span {
  font-size: 36px;
  color: rgb(119, 119, 119);
}

svg g:hover path {
  opacity: 0.7;
  fill: #e7d198;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#targetAreas h1 {
  font-size: 38px;
  font-style: italic;
  line-height: 38px;
  color: #777777;
  margin: 0 0 40px 0;

  @media screen and (max-width: $break-m) {
    margin: 0 0 10px 0;
  }

  em {
    font-size: 28px;
    margin-left: 5px;
    color: #e7d198;
    line-height: 52px;
    padding-bottom: 5px;
  }

  span {
    font-size: 28px;
    color: #777;
    margin: 0 0 0 5px;
  }
}

#blog {
  padding-bottom: 100px;
}

#blog .btn-blog {
  color: #fff;
  background-color: #e7d198;
  border-color: #b29e6c;
  padding: 6px 30px;
}

#blog .btn-blog:hover {
  color: #b29e6c;
  background-color: rgba(0, 0, 0, 0);
  border-color: #b29e6c;
  padding: 6px 30px;
}

.blogs {
  margin-top: 60px;
}
.thumbnail {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: -3 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

#contactForm {
  margin-top: 60px;
  @media screen and (max-width: $break-s) {
    margin: 0;
  }
}

#contact {
  padding: 0px 5px 100px 5px;
  background: #efefef;
  background-position: center;
  background-size: 75%;
}

.contactButton {
  float: left;
  padding: 6px 30px;
  background: #f0dca4;
  border: solid 2px #f0dca4;
  color: #ffffff;
  font-size: 22px;
  margin-right: 20px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
}

.contactButton:hover {
  background: rgba(0, 0, 0, 0);
  border: solid 2px #f0dca4;
  color: #c9b585;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
}

#contact h1 {
  margin-top: 0;
  font-style: italic;
  color: rgb(119, 119, 119);
  @media screen and (max-width: $break-m) {
    padding: 0 20px;
  }
}

#contact .contact-text {
  font-size: 18px;
  line-height: 26px;
  font-style: italic;
  color: rgb(119, 119, 119);
  margin-bottom: 20px;
  @media screen and (max-width: $break-m) {
    padding: 0 20px;
  }
}

#contact textarea.form-control {
  height: 150px;
}

#contact .form-control {
  height: 40px;
  padding: 6px 12px;
  font-size: 16px;
}

#contact .form-group {
  margin-bottom: 20px;
}

/*//////////////////////////////////////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////// Countries Page /////////////////////////////////////*/
/*////////////////////////////////////////////////////////////////////////////////////////////*/

.countries {
  background: #ebebeb;
}

#japanMapBg {
  background: url(assets/imgs/japan-bg.png) no-repeat center;
  width: 100%;
  height: 1810px;
  margin: 200px auto 0 auto;
  position: absolute;
}

#iraqMapBg {
  background: url(assets/imgs/iraq-bg.png) no-repeat 75% 0;
  width: 100%;
  height: 1250px;
  margin: 275px auto 0 auto;
  position: absolute;
}

/*//////////////////////////////////////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////// Countries Page /////////////////////////////////////*/
/*////////////////////////////////////////////////////////////////////////////////////////////*/

.practice-area {
  background: #ebebeb;
}

#personalInjuryBg {
  background: url(assets/imgs/personal-injury-bg.png) no-repeat 75% 0;
  width: 100%;
  height: 1250px;
  margin: 300px auto 0 auto;
  position: absolute;
}

#contactPage #contact {
  height: 100%;
  padding-bottom: 100px;
  background: #efefef 200px;
  background-size: 90%;
}

#contact .form-control {
  border-radius: 0;
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571;
  color: #555;
  background-color: rgba(255, 255, 255, 0.5);
  /* background-image: none; */
  border: 2px solid #ddd;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

#contact textarea.form-control {
  height: 150px;
}

#contact .form-control:focus {
  border: 2px solid #f0dca4;
  -webkit-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

#contact .contact-text {
  font-size: 22px;
  line-height: 26px;
  font-style: italic;
  color: rgb(119, 119, 119);
}

.contact-info {
  float: left;
  padding: 0 20px;
}

#contactInfoWrapper {
  padding-left: 40px;
  border-left: #d5d5d5 solid 2px;
  @media screen and (max-width: $break-s) {
    padding-left: 0px;
    border-left: 0px;
    border-bottom: #d5d5d5 solid 2px;
    margin-bottom: 20px;
  }
}

.map-canvas {
  margin-top: 90px;
  width: 100%;
  height: 400px;
  border-bottom: 2px solid rgb(213, 213, 213);
}

.map-canvas img {
  max-width: none;
}

/*//////////////////////////////////////////////////////////////////////////////////////////////*/
/*//////////////////////////////////////// About Page /////////////////////////////////////*/
/*////////////////////////////////////////////////////////////////////////////////////////////*/

.about-section-header {
  margin-top: 100px;
  width: 100%;
  padding-bottom: 40px;
  text-align: center;
}

.about-section-header h3 {
  font-family: "EB Garamond", serif;
  font-size: 42px;
  color: #777;
  margin-top: 12px;
}

.about-section-header em {
  font-family: "EB Garamond", serif;
  font-size: 32px;
  color: #e7d198;
}

#aboutDividerStart {
  width: 20%;
  border-bottom: 4px solid rgb(231, 209, 152);
  margin: 40px auto;
}

#teamSection img {
  width: 100%;
}

.about-dba {
  background: #ebebeb;
}

.image-underline {
  border-bottom: 2px solid rgb(178, 178, 178);
  margin-top: -2px;
}

.about-section-content {
  padding-top: 0;
  font-size: 22px;
  margin-top: 10px;
  line-height: 34px;
  color: #777;
  font-style: italic;
  margin-bottom: 40px;
}

.intro-video iframe,
.intro-video img {
  width: 100%;
  height: 400px;
}

.intro-video-wrapper {
  padding: 60px 0;
}

.team-member-icon {
  border-bottom: 2px solid rgb(216, 216, 216);
  overflow: hidden;
  width: 80%;
  margin: 20px auto 0 auto;
  padding: 5px 0 0;
  text-align: center;
}

.team-member-icon img {
  width: 80%;
}

.team-member-wrapper {
  padding: 10px;
}

p.team-member-name {
  font-size: 28px;
  text-align: center;
  line-height: 80px;
  margin: 0 auto;
  border-bottom: 1px solid rgb(216, 216, 216);
  /* padding: 0 10px; */
}

.team-tabs-wrapper {
  margin-top: 40px;
  margin-bottom: 100px;
}

.desktop-tabs {
  @media screen and (max-width: $break-s) {
    display: none;
  }
}

.mobile-tabs {
  display: none;
  @media screen and (max-width: $break-s) {
    display: block;
  }
}

.no-mobile {
  display: block;
  @media screen and (max-width: $break-s) {
    display: none;
  }
}

.no-desktop {
  display: none;
  @media screen and (max-width: $break-m) {
    display: block;
  }
}

#teamMemberTitle {
  width: 40%;
  float: left;
  @media screen and (max-width: $break-s) {
    width: 100%;
  }
}

.team-member-tab {
  width: 15%;
  text-align: center;
  float: left;
  @media screen and (max-width: $break-s) {
    width: 25%;
    margin: 50px 0 0 0;
  }
}

.no-top {
  @media screen and (max-width: $break-s) {
    margin-top: 0 !important;
  }
}

.team-member-tab-icon {
  opacity: 0.7;
  margin: 0 auto;
  padding-top: 4%;
  width: 60%;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid rgb(216, 216, 216);
}

#marioAbout #marioActive .team-member-tab-icon {
  border: 2px solid rgb(231, 209, 152);
  opacity: 1;
}

#randellAbout #randellActive .team-member-tab-icon {
  border: 2px solid rgb(231, 209, 152);
  opacity: 1;
}

#alexAbout #alexActive .team-member-tab-icon {
  border: 2px solid rgb(231, 209, 152);
  opacity: 1;
}

#joshuaAbout #joshuaActive .team-member-tab-icon {
  border: 2px solid rgb(231, 209, 152);
  opacity: 1;
}

.team-member-tab p {
  font-size: 18px;
  margin-top: 15px;
}

.team-member-tab-icon img {
  width: 100%;
}

.accordion-content {
  display: none;
}

.accordion-content.active {
  display: block;
}

.accordion-title {
  color: #e7d198;
  text-decoration: none;
  font-size: 22px;
  line-height: 60px;
  border-bottom: #d8d8d8 solid 1px;
}

.accordion-title p {
  margin: 0;
}

.accordion-title p span {
  margin: 0 10px;
}
.accordion-content {
  font-size: 18px;
  padding: 20px 0;
  border-bottom: #d8d8d8 solid 1px;
}

.team-tab-body h1 {
  padding-bottom: 25px;
  border-bottom: #d8d8d8 solid 1px;
}

.team-tab-body p {
  padding: 15px 0;
  font-size: 18px;
  text-align: left;
}

.team-tab-body {
  padding: 0 25px;
}

.panel-default > .panel-heading {
  background-color: rgba(245, 245, 245, 0);
  border-bottom: 2px solid #d5d5d5;
}

.panel {
  background-color: rgba(255, 255, 255, 0);
  border: 0 solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 0 solid #ddd;
  border-bottom: 2px solid #d5d5d5;
}

.panel-title {
  font-size: 22px;
  line-height: 50px;
  text-align: center;
  color: #337ab9;
}

.panel-heading {
  padding: 0 0;
}

.accordion-toggle p {
  margin: 0 0;
  text-align: left;
  padding: 0 25px;
}

.accordion-toggle p span {
  margin: 0 10px 0 0;
}

.panel-body {
  text-align: left;
}

.panel-group .panel + .panel {
  margin-top: 0;
}

#mobileMenu {
  position: fixed;
  width: 100%;
}

.mobile-menu-button img {
  width: 30px;
  margin-left: 10px;
}

.mobile-menu-button {
  float: right;
  color: #fff;
  margin: 30px 30px;
  font-size: 18px;
}

#mainMenu.is-disabled {
  display: none;
}

#mainMenu.is-active {
  display: block;
}

#mobileMenu.is-disabled {
  display: none;
}

#mobileMenu.is-active {
  display: block;
}

.footer-section-wrapper {
  padding: 10%;
  @media screen and (max-width: $break-s) {
    padding: 10px 0;
  }
}

#footerLogo img {
  width: 80%;
  padding: 20px 0;
}

footer {
  background-color: #252f35;
  font-size: 16px;
  padding: 20px;
  line-height: 24px;
  color: #fff;
}

footer.footer h3 {
  color: #e7d198;
  margin-bottom: 20px;
}

footer ul {
  list-style: none;
  padding-left: 0;
}

footer ul li {
  padding: 5px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

footer.footer a {
  color: #fff;
}

.bottom-footer {
  padding: 25px 0 12px 0;
  background-color: #1b1f21;
  text-align: center;
}

#sideMenu {
  width: 300px;
  overflow: scroll;
  font-size: 21px;
  position: fixed;
  background-color: #252f35;
  height: 100%;
  top: 0;
  right: 0;
  margin-right: -300px;
  will-change: transform;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#sideMenu img {
  width: 30px;
  margin-right: 20px;
}

#sideMenu ul {
  list-style: none;
  padding-left: 0;
}

#sideMenu li {
  padding: 15px 10px 15px 0;
  color: #fff;
  border-bottom: solid 1px rgba(255, 255, 255, 0.16);
}

#sideMenu li a {
  padding-left: 20px;
  color: #fff;
}

#sideMenu ul.submenu-menu li {
  padding-left: 35px !important;
}

#sideMenu ul.submenu-menu li a {
  padding-left: 0 !important;
}

#sideMenu .submenu-menu li:first-child {
  margin: 13px 0px 7px 0px;
  border-top: solid 1px rgba(255, 255, 255, 0.16);
}

#sideMenu .submenu-menu li:last-child {
  margin: 13px 0px -15px 0px;
  border-bottom: solid 0px rgba(255, 255, 255, 0) !important;
}

#sideMenu .submenu-menu:first-child {
  margin-left: 0;
}

#sideMenu .submenu-menu li {
  margin: -7px 0px 7px 0px;
  width: 100%;
}

.video-intro-wrapper {
  position: fixed;
  top: 250px;
  z-index: 999999;
  right: 0;
  width: 0;
  @media screen and (max-width: $break-s) {
    display: none;
  }
}

.video-intro-position {
  background-color: #333;
  height: 310px;
  float: right;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin-right: -440px;
  width: 490px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  padding: 20px 5px;

  .EmberYoutube-player {
    width: 360px;
    height: 270px;
  }
}

.video-intro-wrapper img {
  float: left;
  width: 25px;
  margin: 10px;
}

.video-intro-position .EmberYoutube {
  float: left;
}

.homeButton a {
  color: #ffedbd;
}

// Accordion 
.accordion-item-title {
  font-size: 22px;
  line-height: 50px;
  text-align: center;
  color: #337AB9;
  border-bottom: 2px solid #D5D5D5;
}

.accordion-item-panel {
  padding: 15px;

  .is-active & {
    border-bottom: 2px solid #D5D5D5;
  }

  ul {
    text-align: left;
  }
}